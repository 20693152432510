.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

sup {
  margin-left: 2px;
}

.app-header {
  background-color: rgb(39,59,150);
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.brand-logo {
  height: 60px;
}

.study-breadcrumbs {
  margin-top: 14px;
  margin-bottom: 10px;
  vertical-align: middle;
}

.study-breadcrumb-logo {
  margin-right: 10px;
  height: 40px;
}

.cypress-breadcrumb-logo {
  margin-top: -14px;
}

li.study-breadcrumb-text {
  margin-top: 4px;
}

.cancel-button {
  color: white;
  text-decoration: none;
}

.margin-top {
  margin-top: 20px;
}

.margin-bottom {
  margin-bottom: 20px;
}

.margin-left {
  margin-left: 10px;
}

.margin-footer {
  margin-bottom: 90px;
}

.align-vertical {
  margin-top: auto;
  margin-bottom: auto;
}

.full-width {
  width: 100%;
}

.form-label {
  text-align: left;
  font-weight: bold;
  color: #999;
}

nav.navbar {
  background-color: rgb(39,59,150);
}

.footer {
  background-color: rgb(39,59,150);
  padding: 20px;
  padding-left: 30px;
}

.btn-primary {
  background-color: #00bfde;
  border-color: #00bfde;
}

.btn-outline-primary {
  color: #00bfde;
  border-color: #00bfde;
}

.btn-primary:hover {
  border-color: #00bfde;
  background-color: #00bfde;
  opacity: 1;
}

.btn-outline-primary:hover {
  border-color: #00bfde;
  background-color: #00bfde;
  opacity: 1;
}

.btn-primary.disabled, .btn-primary:disabled {
  border-color: #00bfde;
  background-color: #00bfde;
  opacity: 0.6;
}

nav div.container {
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
  max-width: 100%;
}

.tile-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tile-card-container {
  width: 15.5rem;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tile-image {
  width: 230px;
}

.cypress-tile-image {
  margin-top: 10px;
  margin-left: 5px;
}

.input-group-text {
  background: #efefef;
}

.card-header {
  background: #efefef;
}

.card.tile-card {
  width: 15rem;
  cursor: pointer;
  flex: none;
  height: 210px;
}

.card.tile-card:hover {
  opacity: 0.7;
}

.card.tile-card .tile-icon-container {
  background: #efefef;
  text-align: center;
  margin-bottom: 20px;
}

.card.tile-card .card-body {
  padding: 0;
}

.card.tile-card .tile-icon-container .tile-icon {
  color: rgb(39,59,150);
  font-size: 4em;
  margin-top: 30px;
  margin-bottom: 30px;
}

.card.tile-card .card-text {
  font-size: 0.9em;
  font-weight: bold;
  text-align: center;
  color: #999;
  margin-bottom: 20px;
}

.breadcrumb-item {
  margin-top: auto;
  margin-bottom: auto;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">");
}

a {
  color: rgb(39,59,150);
  opacity: 0.8;
  text-decoration: none;
}

.nav-tabs li .nav-link {
  color: rgb(39,59,150);
  opacity: 0.8;
}

.list-group-item-action {
  cursor: pointer;
}

.selectable-icon {
  cursor: pointer;
  font-size: 2em;
  width: 60px;
  text-align: center;
}

.clickable {
  cursor: pointer;
}

.selectable-icon:hover {
  background-color: #eee;
}

.icon-edit-button {
  font-size: 1.5em;
  cursor: pointer;
}

.icon-edit-button:hover {
  opacity: 0.7;
}

.modal-70w {
  width: 60%;
  max-width: 60%;
}

.left-column {
  position: fixed;
  top: 4rem;
  left: 0;
  width: 220px;
  z-index: 1000;
  height: calc(100vh - 4rem);
  border-right: 1px solid #CCC;
  padding-right: 0px;
  padding-left: 0px;
}

.right-column {
  margin-left: 220px;
  min-height: 100%;
}

.loading-overlay {
  background-color: rgba(255,255,255, 0.5);
  height: 100%;
  width: 100%;
  min-height: 400px;
  z-index: 2000;
  align-items: center;
  justify-content: center;
  position: fixed;
  display: flex;
}

.legend-table {
  width: 100%;
  margin-bottom: 20px;
}

.legend-key {
  width: 40px;
}

.button-column {
  width: 30px;
}

.icon-table-container {
  width: 460px;
  height: 400px;
  overflow-y: scroll;
}

.legend-modal-key {
  padding: 10px;
  width: 30px;
  vertical-align: top;
}

.legend-modal-footnote {
  padding: 10;
}

.study-list-group-item-icon {
  display: inline;
  float: left;
  margin-top: -5px;
  margin-right: 20px;
}

.superscript-input {
  font-size: 12px;
  padding-top: 0px;
}

.checklist-input-column {
  width: 83%;
}

.study-protocol-logo {
  margin-left: 40px;
  height: 200px;
}

.selected-dropdown {
  background-color: #efefef;
}

.study-protocol-detail {
  text-align: right;
}

.error-modal-icon-container {
  height: 240px;
  background-color: #dc3545;
  text-align: center
}

.error-modal-icon {
  color: white;
  font-size: 160px;
  margin-top: 40px;
}

.error-modal-body {
  text-align: center;
}

.error-page-container {
  text-align: center;
  background-color: #efefef;
  margin: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.error-page-icon {
  font-size: 120px;
  color: #999;
  margin-bottom: 20px;
}

#login-container {
  background-color: #efefef;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#login-container .container {
  text-align: center;
  width: 400px;
  margin-right: auto;
  margin-left: auto;
  background-color: rgb(39,59,150);
  box-shadow: 10px 10px rgba(0,0,0,0.1);
}

.login-error-message-container {
  margin: auto;
  margin-top: -40px;
  width: 80%;
}

.login-error-message-container p {
  font-size: 14px;
  color: #ff7f7f;
}

.login-logo-container {
  margin: 60px;
}

.login-logo-container img {
  height: 120px;
}

.login-content-container {
  width: 100%;
  margin: auto;
  margin-bottom: 30px;
}

.login-button {
  margin: 20px;
}

.login-input {
  margin: 20px;
}

.login-link-button {
  color: white;
  text-decoration: none;
  opacity: 0.7;
}

.login-link-button:hover {
  color: white;
  opacity: 0.8;
}

.login-link-button-container {
  margin-top: -10px;
}

.login-password-requirements-header {
  margin-left: 20px;
  text-align: left;
  margin-bottom: 5px;
  margin-top: 10px;
  font-size: 14px;
  color: white;
}

.login-password-requirements ul {
  margin-left: 20px;
}

.login-password-requirements li {
  text-align: left;
  margin-bottom: 5px;
  font-size: 12px;
}

.login-instructional-text {
  font-size: 0.7em;
  color: white;
}

.valid-text {
  color: #90EE90;
}

.invalid-text {
  color: #ff7f7f;
}

.footer-version { 
  bottom: 0px;
  font-size: 12px;
  position: fixed;
  width: 100%;
  text-align: center;
  opacity: 0.5;
  margin-bottom: 10px;
}